@use 'styles/mixins.scss';
@use 'styles/colors.scss';
@use 'sass:color' as sass;

.video-frame {
  box-shadow: 0 1px 50px sass.scale(colors.$primary, $alpha: -90%);
  position: relative;
  display: block;

  @include mixins.transition;
  cursor: pointer;

  img {
    border: 2px solid transparent;
  }

  &:hover {
    box-shadow: 0 1px 50px sass.scale(colors.$primary, $alpha: -70%);
    transform: translateY(-4px);

    img {
      border: 2px solid colors.$primary;
      border-radius: 5px;
    }

    &::after {
      background-color: #00000011;

      @include mixins.transition;
    }
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0 0 0 0;
    background-color: #00000049;
  }
}
