@mixin transition($duration: 100ms, $prop: all, $timing: cubic-bezier(0.56, 1.12, 0.68, 0.79)) {
  transition: $duration $prop $timing;
}

@mixin roulz-gradient() {
  background: linear-gradient(45deg, #304ffe, #651fff);
  background: -webkit-linear-gradient(45deg, #304ffe, #651fff);
}

@mixin box-shadow($strength: 1) {
  @if $strength == 1 {
    box-shadow: 0 1px 6px #0000000d;
  } @else if $strength == 2 {
    box-shadow: 0 1px 15px #0000000d;
  }
}
