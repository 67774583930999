@use 'include-media' as *;

@use 'styles/colors.scss';

.Home {
  $b: &;

  padding: 0;

  &__content {
    position: absolute;
    top: 0;
    padding: 80px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    &__left {
      grid-area: left;
    }

    &__right {
      grid-area: right;
      justify-self: right;
      align-self: flex-end;

      @include media('<tablet') {
        display: none;
      }
    }

    #{$b}__title {
      font-size: 4em;
      letter-spacing: 5px;
      text-transform: uppercase;
      font-family: 'Roboto Mono';
      margin-bottom: 0;
    }

    #{$b}__subtitle {
      color: colors.$grey-3;
      letter-spacing: 3px;
      margin-bottom: 60px;
    }

    @include media('<1250px') {
      flex-flow: column;
    }

    @include media('<tablet') {
      padding: 20px;
    }
  }

  &__buttons {
    margin-top: 40px;
  }

  &__video {
    img {
      width: 400px;
    }

    width: 400px;

    @include media('<1020px') {
      width: 200px;
      img {
        width: 200px;
      }
    }
  }
}
