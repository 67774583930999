@use './colors.scss';
@use './variables.scss';
@use './mixins.scss';
@use './animations.scss';

@use 'include-media' as *;

@import '@fontsource/roboto-mono';
@import '@fontsource/roboto';

html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;

  font-family: 'Roboto';
  font-size: 16px;

  @include media('<tablet') {
    font-size: 12px;
  }

  @include media('<480px') {
    font-size: 10px;
  }
}

.App {
  background: colors.$black;
  color: colors.$grey-2;
  height: 100vh;
  width: 100vw;
}

a {
  color: colors.$primary-accent;
  letter-spacing: 1px;

  @include mixins.transition();

  text-transform: uppercase;

  &:hover {
    color: colors.$primary;
  }
}

a.button {
  color: colors.$primary;
  border: 1px solid colors.$primary;
  padding: 8px 16px;
  margin: 0 16px 0 0;
  text-decoration: none;
  display: inline-block;

  @include mixins.transition(200ms);

  &:hover {
    border: 1px solid transparent;
    color: colors.$white;
    @include mixins.roulz-gradient();
    transform: translateY(-2px);
  }

  @media all and (max-width: 480px) {
    margin: 0 8px 0 0;
    padding: 6px 8px;
  }
}

.roulz-text {
  font-weight: bold;
  letter-spacing: 1px;
  background: linear-gradient(45deg, #651fff, #304ffe);
  background: -webkit-linear-gradient(45deg, #651fff, #304ffe);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
