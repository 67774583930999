@use 'styles/variables.scss';
@use 'styles/colors.scss';

.page {
  padding: 20px 40px;
  background-color: colors.$black;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
}
