$primary: #304ffe;
$primary-accent: #2979ff;

$white: #f5f5f5;

$grey-2: #eeeeee;
$grey-3: #e0e0e0;
$grey-4: #bdbdbd;
$grey-5: #9e9e9e;
$grey-6: #757575;
$grey-7: #616161;
$grey-8: #424242;

$black: #212121;
