@use './variables.scss';
@use './mixins.scss';

@mixin fade-animation {
  content: '';
  position: absolute;
  height: calc(100vh - variables.$nav-height);
  left: 0;
  bottom: 0;
  animation: slide-in 0.8s;
  opacity: 1;

  @include mixins.roulz-gradient;
}

.fade-enter {
  opacity: 0;
  z-index: 1;

  &:after {
    @include fade-animation;
  }
}

.fade-enter-done {
  &:after {
    @include fade-animation;
  }
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

@keyframes slide-in {
  0% {
    left: 100%;
    right: 0;
  }
  50% {
    left: 0;
    right: 0;
  }
  100% {
    left: 0;
    right: 100%;
  }
}
