@use 'include-media' as *;

@use 'styles/colors.scss';
@use 'styles/variables.scss';
@use 'styles/mixins.scss';

.header {
  height: variables.$nav-height;
  width: 100vw;
  display: flex;
  z-index: 100;
  background-color: colors.$grey-2;
  display: flex;
  justify-content: space-between;
  padding: 0 40px;

  &__logo {
    margin: 8px 0;

    img {
      height: 100%;
    }

    @include media('<tablet') {
      margin: 16px 0;
    }
  }

  &__menu {
    &__item {
      height: 100%;
      position: relative;
      text-decoration: none;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 24px;
      background: none;

      &::before {
        content: '';
        position: absolute;
        color: colors.$white;
        transform: translateY(-100%);
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: colors.$primary;

        @include mixins.transition($duration: 200ms);
      }

      &:hover {
        color: colors.$white;

        &::before {
          transform: none;
        }
      }
    }
  }

  @include media('<tablet') {
    padding: 0 20px;
  }
}
