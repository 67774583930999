@use './styles/colors.scss';
@use './styles/variables.scss';

.App {
  display: grid;
  grid-template-rows: 60px auto;

  .content {
    position: relative;

    @media all and (max-width: 480px) {
      padding: 20px;
    }
  }
}
